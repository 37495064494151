import React, {useState, useEffect, useRef, useLayoutEffect} from 'react';
import * as AWS from 'aws-sdk'
import {
     Button,
     Box,  Flex, Image,
     Center,
     Avatar, AvatarBadge, AvatarGroup,
     Popover,
     PopoverTrigger,
     PopoverContent,
     PopoverHeader,
     PopoverBody,
     PopoverFooter,
     PopoverArrow,
     PopoverCloseButton,
     PopoverAnchor,
     AspectRatio
} from "@chakra-ui/react"
import { IoMdArrowBack, IoMdArrowForward } from 'react-icons/io';
import { useQuery, useMutation, useQueryClient} from 'react-query'
import {useParams} from "react-router-dom";
import Botones from  '../Cliente/Buttons'
import { useMediaQuery } from '../../hooks/mediaQuery'
import { RenderIf } from '../../hooks/renderIf';
import LogoNctech  from '../../assets/LOGOS_NCTECH-01.svg'
import { motion } from "framer-motion";
import {connect} from 'react-redux'
import { get_avatar, get_proyecto } from '../../actions';
import Fondo from '../../assets/FONDOAZUL.png'
import EMBLEMA from '../../assets/EMBLEMA.png'
import { Document,Page } from 'react-pdf/dist/esm/entry.webpack';
import axios from 'axios';
//AWS.config.update({region: 'us-east-1', secretAccessKey: 'DNtf9KVvFwLGdzIFSq4vR3V8+K+4vMAfIO/DdKnv', accessKeyId:'AKIAWLUS3YRU4LTKLQHG'});
AWS.config.update({ region: 'us-east-1', secretAccessKey: '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', accessKeyId: 'AKIARVGY33F62XNKJJK3' });
function getQueryParams(qs) {
    qs = qs.split('+').join(' ');

    var params = {},
        tokens,
        re = /[?&]?([^=]+)=([^&]*)/g;

    while (tokens = re.exec(qs)) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }

    return params;
}

const _ShareProject = (props) => {
    const [datos, setDatos] = useState([])
    const docClient = new AWS.DynamoDB.DocumentClient({apiVersion: '2022-01-01'})
    const listElement = useRef();
    const [urlStrapi, setUrlStrapi] = useState(process.env.REACT_APP_URL_STRAPI)
    const [to, setTo] = useState()
    const [src, setSrc] = useState()
    const [datos_videos, setDatos_videos] = useState([])
    const [vendedorData, setVendedorData] = useState()
    let { id, token, proyectoParametro, nombre, proyecto, proyectoId, vendedor, file, meid, type, documento, usuario } = useParams();
    const breakpoint = useMediaQuery('(max-width: 1200px)');
    const breakpoint1201 = useMediaQuery('(max-width: 1601px)');
    const { Imagen, proyectoRData } =  props || {};
    const [office, setOffice] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [ratio, setRatio] = useState(17/11);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pdfFile, setPdfFile] = useState()
    const documentRef = useRef(null);
    const [urlCassandra, setUrlCassandra] = useState(process.env.REACT_APP_URL_CASSANDRA)
    const [tokenCasandra, setTokenCassandra] = useState(process.env.REACT_APP_TOKEN_CASSANDRA)
    const [descarga, setDescarga] = useState()
    const [impresion, setImpresion] = useState()
    const [pageInfo, setPageInfo] = useState([]);
    const [maxHeight, setMaxHeight] = useState(0);
    //async function onDocumentLoadSuccess(successEvent, {numPages}){
      //setNumPages(numPages);
      //setPageNumber(1);
      async function onDocumentLoadSuccess(Document){
        setNumPages(Document.numPages);
        setPageNumber(1);
        const data =  await Document.getData()
        const blob = new Blob([data], { type: 'application/pdf' })
        setPdfFile(blob)
        
        
        const { numPages } = Document;
            setNumPages(numPages);

            const sizes = [];
            for (let i = 1; i <= numPages; i++) {
            Document.getPage(i).then((page) => {
                const { width, height } = page.getViewport({ scale: 1 });
                
                let maxPageHeight = 0;
                maxPageHeight = Math.max(maxPageHeight, height);
                
                sizes.push({ width, height });
                if (sizes.length === numPages) {
                    setPageInfo(sizes);
                    console.log("SIZE",sizes)
                }
                setMaxHeight(maxPageHeight);
            });
            }

       // window.print(URL.createObjectURL(blob));
    }



    async function fetchDocumento() {
        console.log("refetch", documento)
        //const urlC = `${urlCassandra}collections/attachmens/${documento}`
        const urlC = `https://servicesemail-production-899b13b908d7.herokuapp.com//api/documentos/${documento}`
        const ventasData = await axios(urlC, {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                //'X-Cassandra-Token': tokenCasandra
            }
        })
       
        
        const presentaciones =  ventasData.data
        setDescarga(presentaciones.download)
        setImpresion(presentaciones.print)
        console.log("[presentaciones]",presentaciones)
        //return presentaciones
        
    
}
    function changePage(offSet){
      setPageNumber(prevPageNumber => prevPageNumber + offSet);
    }
  
    function changePageBack(){
      changePage(-1)
    }
  
    function changePageNext(){
      changePage(+1)
    }
    useEffect(() => {
      const handleResize = () => {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const newRatio = windowWidth / windowHeight;
        setRatio(newRatio);
      };
  
      window.addEventListener('resize', handleResize);
      handleResize(); // Calcula el ratio inicial al cargar el componente
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const useWindowDimensions = () => {
        const [windowDimensions, setWindowDimensions] = useState({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      
        useLayoutEffect(() => {
          const handleResize = () => {
            setWindowDimensions({
              width: window.innerWidth,
              height: window.innerHeight,
            });
          };
      
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        }, []);
      
        return windowDimensions;
      };
    const { width, height } = useWindowDimensions();

      

    const fetchData = (tableName) => {
        var params = {
            TableName: tableName,
            //   FilterExpression: "#nom = :name",
            //   ExpressionAttributeNames: {
            //       "#nom": "Nombre",
            //   },
            //   ExpressionAttributeValues: {
            //       ":name": "Jacinto"
            //   }
        }
    
        docClient.scan(params, function (err, data) {
            if (!err) {
                setDatos(data.Items)
                console.log(data.Items)
            }
        })
    }
    const putData = (tableName , data) => {
        var params = {
            TableName: tableName,
            Item: data
        }
        
        docClient.put(params, function (err, data) {
            if (err) {
                console.log('Error', err)
            } else {
                console.log('Success', data)
            }
        })
    }
    const fetchDataFormDynamoDb = () => {
       const res =  fetchData('Clientes')
        console.log("Resultado:", res)
       //setDatos(res.Items)
       //return res
      }
      const addDataToDynamoDB = async () => {
        const userData = {
          Nombre:"Juan Carlos Santiago",
          Prueba:"Desde React",
          FechaCreacion: "Hoa"
        }
        
        await putData('Clientes' , userData)
      }
    const regresar = () => {
        window.location.href = `/me/${meid}/${proyectoId}/${nombre}/${proyecto}/${vendedor}`
    }
    const actualizar = () => {
        const query = getQueryParams(document.location.search);
        var params = {
            TableName: 'Bitacora',
            Key: {
                "id" : query.id
            },
            UpdateExpression: "set open = :open, fechaFin = :fechaFin",
            ExpressionAttributeValues: {
                ":open": false,
                ":fechaFin": Date.now()

            },
        }
        docClient.update(params, function (err, data) {
            if (err) {
                console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
            } else {
                //res.status(200).json({ data })
                console.log(data)
            }
        });
    }
    // const fetchDataVideos = () => {
    //     // const query = getQueryParams(document.location.search);
    //     // console.log("Query:", query)
    //     // const proyecto = query.token.substring(query.token.indexOf(',') + 1)
    //     // console.log("proyecto1:", proyecto)
    //     var params = {
    //         TableName: 'Media',
    //          FilterExpression: "#nom = :name and #estado = :estado and #proyecto = :proyecto and #tipo = :tipo",
    //          ExpressionAttributeNames: {
    //              "#nom": "cliente",
    //              "#estado": "estatus",
    //              "#proyecto": "proyecto",
    //              "#tipo": "tipo"
    //          },
    //          ExpressionAttributeValues: {
    //              ":name": proyectoId,
    //              ":estado": "visible",
    //              ":proyecto": proyecto,
    //              ":tipo": "presentacion"
    //          }
    //     }
    //     docClient.scan(params, function(err, data) {
    //         if (err) {
    //             console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
    //         } else {
    //             console.log("Query succeeded.", data.Items);
    //             setDatos_videos(data.Items)
    //         }
    //     });
    // }
    useEffect(()=>{
        const query = getQueryParams(document.location.search);
        setTo(query.token)
        console.log(file)
        if(type == "presentacion"){
           setSrc("https://view.officeapps.live.com/op/embed.aspx?src=https://nctech-attachmens.s3.amazonaws.com/" + file )
           console.log("[URL]", src)

        }else {
            
            setSrc("https://nctech-attachmens.s3.amazonaws.com/" + file) 
        }
       // fetchDataFormDynamoDb()
        console.log("Vendedor",vendedor)
        props.get_avatar(vendedor, urlStrapi)
        props.get_proyecto(proyecto)
        //fetchVendedor()
        console.log(query)
        const noRightClick = document.getElementById("preview");
       
        noRightClick.addEventListener("contextmenu", e => e.preventDefault());
        if(type == "presentacion"){
        const noRightClickIframe = document.getElementById("presentaciones");
        //noRightClickIframe.addEventListener("contextmenu", e => e.preventDefault());
        }
        if(type == "propuesta"){
         // Capturamos el evento del clic derecho en el frame
        document.getElementById("pdfs").addEventListener("contextmenu", function(event) {
            // Anulamos el comportamiento predeterminado del clic derecho
        event.preventDefault()
        })}
        if(type == "video"){
            // Capturamos el evento del clic derecho en el frame
           document.getElementById("videos").addEventListener("contextmenu", function(event) {
               // Anulamos el comportamiento predeterminado del clic derecho
           event.preventDefault()

           const video = document.getElementById("myVideo");

           video.addEventListener("play", function() {
               video.preload = "auto";
           });
       
           video.addEventListener("pause", function() {
               video.preload = "none";
           });

           })}
    },[])
    
    useEffect(() => {
        fetchDocumento()
        function handleContextMenu(event) {
          event.preventDefault();
        }
    
        const iframe = listElement.current;
    
        if (iframe) {
          iframe.addEventListener('contextmenu', handleContextMenu);
        }
    
        return () => {
          if (iframe) {
            iframe.removeEventListener('contextmenu', handleContextMenu);
          }
        };
      }, []);
    //const {refetch: refetch_videos} = useQuery('VIDEOS', fetchDataVideos)
    const handleDownload = () => {
        fetch(src)
          .then(response => response.blob())
          .then(blob => {
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'documento.pdf';
            link.click();
            URL.revokeObjectURL(url);
          });
      };
      
      const handlePrint = () => {
        fetch(src)
        .then(response => response.blob())
        .then(blob => {
          const blobUrl = URL.createObjectURL(blob);
          const printWindow = window.open(blobUrl, '_blank');
          printWindow.onload = function() {
            printWindow.print();
            URL.revokeObjectURL(blobUrl);
          };
        });
      };

      console.log("[DESCARGA]",descarga, impresion)
    return (
        <Box h={'100vh'} w={'100%'} position={'relative'} id={'preview'} bgImage={`url(${Fondo})`} border={0}>
            <Flex direction={'column'} h={'100vh'} w={'100%'}>
                {/* <RenderIf isTrue={height >= 907} > */}
    
                <RenderIf isTrue={!breakpoint && height >= 906} >
                    <RenderIf isTrue={type=="presentacion"}>
                    <Box bgImage={`url(${Fondo})`}
                        position={'absolute'}
                        top={'-1'}
                        w={'100%'}
                        zIndex={5}
                        h={breakpoint1201 ? '13vh' : '13vh'}
                    >
                    </Box>
                    </RenderIf>
                    <Flex direction={'row'} w={'100%'} bgImage={`url(${Fondo})`} position={'relative'}>
                        <Box bgImage={`url(${Fondo})`}
                            zIndex={6}
                            w={'22%'}
                            position={'relative'}
                            left={"-1"}
                            h={'100vh'}>
                            <Center>
                                <Box mt={5} zIndex={9}>
                                    <Image src={LogoNctech} w={'150px'} h={'60px'}/>
                                </Box>
                            </Center>
                        </Box>
                        <Box w={'60%'}
                            position={'relative'}
                            left={'-2'}
                            top={0}
                            h='100%'
                            zIndex={'4'}
                            m={0}
                            p={0}
                        >
                            
                           <RenderIf isTrue={type=="presentacion"}>
                   

                            <AspectRatio w={['101%', '100%']} ratio={19/11} position={'relative'} top={28}> 
                            <Box  borderRadius={15} boxShadow={'lg'} overflow={'hidden'} h={`${height}px`} w={'100%'} m={0}>
                                <iframe
                                    style={{placeItems: 'center', display: 'grid', border: '0px', overflow:'hidden' }}
                                    id={'presentaciones'}
                                    src={src}
                                    width="101%"
                                    height="101%"
                                    allow="*"
                                    ref={listElement}
                                >
                                </iframe>
                                </Box>
                            </AspectRatio>  
                           
                        
                           <div style={{
                                position: 'absolute',
                                bottom: '10%',
                                right: '0px',
                                width: '100px',
                                height: '20%',
                                backgroundColor: 'transparent',
                                zIndex: 2
                            }}>
                                <p style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: '#ff2',
                                    fontSize: '16px',
                                    textAlign: 'center'
                                }}></p>
                            </div>
                            <div style={{ 
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '77.5%',
                            backgroundColor: 'transparent',
                            zIndex: 1
                            }}>
                            <p style={{ 
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: '#fff',
                                fontSize: '20px',
                                textAlign: 'center'
                            }}></p>
                        </div> 
                           </RenderIf>
                           <RenderIf isTrue={type=="propuesta"}>
                                <Center>

                                    <Box id={'pdfs'} mt={20}>
                                        <Center>
                                            <Box color={'white'} bg={'gray.800'} alignSelf={'center'}
                                                p={2}
                                                borderRadius={5}
                                                top={10}
                                                zIndex={999}

                                                fontSize={'13px'}
                                            > Total de Páginas {numPages}</Box>

                                            <Flex>
                                                         {/* Botón de Descargar */}
                                                <RenderIf isTrue={descarga == true}>
                                                <Button
                                                    onClick={handleDownload}
                                                    bg="gray.800"
                                                    color="white"
                                                    _hover={{ bg: 'gray.700' }}
                                                    rounded="5"
                                                    h="40px"
                                                    ml={1}
                                                    zIndex={999}
                                                    fontSize={'13px'}
                                                >
                                                    Descargar 
                                                </Button>
                                             </RenderIf>

                                            {/* Botón de Imprimir */}
                                            <RenderIf isTrue={impresion == true}>
                                            <Button
                                                onClick={handlePrint}
                                                bg="gray.800"
                                                color="white"
                                                _hover={{ bg: 'gray.700' }}
                                                rounded="5"
                                                h="40px"
                                                zIndex={999}
                                                ml={1}
                                                fontSize={'13px'}
                                            >
                                                Imprimir
                                            </Button>
                                            </RenderIf>
                                            </Flex>
                                        </Center>
                                      
                                        {/* <Document file={src} onLoadSuccess={onDocumentLoadSuccess} id={'pdf-container'}>
                                            <Page height="800" pageNumber={pageNumber} />
                                        </Document> */}
                                        <Box
                                                position="fixed"
                                                top="50%"
                                                left="50%"
                                                transform="translate(-50%, -50%)"
                                                overflowY="scroll"
                                                maxHeight="85vh"
                                                rounded={15}
                                                mt={3}
                                                scroll={'none'}
                                                // Ajusta la altura máxima según tus necesidades
                                                >

                                                <Document file={src} onLoadSuccess={onDocumentLoadSuccess} >
                                                {Array.from(new Array(numPages), (el, index) => (
                                                   
                                                <Page
                                                    key={index}
                                                    pageNumber={index + 1}
                                                    width={600}
                                                    height={'700'}
                                                    borderBottom={'12px'}
                                                    renderMode="canvas"
                                                    clip={{ x: 100, y: 100, width: 400, height: 400 }}
                                                    className={{marginBottom: 2}}
                                                />
                                                
                                                ))}
                                                </Document>


                                                </Box>
                                        <Flex justify={'center'} position={'relative'} top={-5}>
                                            {/* <Center>

                                                {pageNumber > 1 &&
                                                    <Button onClick={changePageBack}
                                                        bg={'gray.800'} color={'white'}
                                                        _hover={{ bg: 'gray.700' }}
                                                        rounded={'full'}
                                                        h={'40px'}

                                                        mr={1}
                                                    >
                                                        <svg width={'10px'} height={'20px'} viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M768 903.232l-50.432 56.768L256 512l461.568-448 50.432 56.768L364.928 512z" fill="#fff"></path></g></svg>
                                                    </Button>
                                                }
                                                {
                                                    pageNumber < numPages &&
                                                    <Button onClick={changePageNext}
                                                        bg={'gray.800'} color={'white'}
                                                        _hover={{ bg: 'gray.700' }}
                                                        rounded={'full'}
                                                        h={'40px'}

                                                    >
                                                        <svg width={'10px'} height={'20px'} viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z" fill="#fff"></path></g></svg>

                                                    </Button>
                                                }
                                            </Center> */}

                                        </Flex>


                                    </Box>
                                </Center>
                           </RenderIf>
                           <RenderIf isTrue={type=="video"}>
                                <Center>
                                    <Box id={'videos'} mt={40}>
                                       
                                        <Flex justify={'center'} position={'relative'} top={-5}>
                                            <Center>

                                                <video controls autoPlay={false} preload="auto" width="auto" height="40%">
                                                    <source src={src} controls />
                                                </video>
                                            </Center>

                                      
                                        </Flex>


                                    </Box>
                                </Center>
                           </RenderIf>
                        </Box>
                        <Box zIndex={6} w={'15%'} h={'100vh'} transform={'90'} bgImage={`url(${Fondo})`}
                        border={'0'} left={'-3'} position={'relative'}>
                            <Box mt={5} ml={5} >
                                {/* <Flex justify={'center'}>
                                    {
                                        Imagen === undefined ?
                                        <Avatar src={"user.username"} alignSelf={'center'} _hover={{ cursor: 'pointer' }} w={'30px'} h={'30px'} />
                                        :
                                        <Avatar alignSelf={'center'} src={Imagen} _hover={{ cursor: 'pointer' }} w={'30px'} h={'30px'} />
                                    }
                                    <Flex direction={'column'}>
                                        <Box alignSelf={'center'} ml={'2'} color={'white'} fontWeight={'medium'} fontSize={'14px'}>Asesor de Ventas</Box>
                                        <Box alignSelf={'center'} ml={'2'} color={'white'} fontWeight={'medium'} fontSize={'14px'}>{proyectoRData.vendedorName}</Box>
                                        <p>Width: {width}</p>
                                        <p>Height: {height}</p>
                                    </Flex>
                                </Flex> */}
                                {/* <Flex justify={'start'} direction={'column'} zIndex={99999} w={'300px'} h={'500px'} mt={5}>
                                        {datos_videos.map((p, i) => {
                                            return (
                                                <Box rounded={'lg'} w={'auto'} bg={'gray.200'} overflow={'hidden'} >
                                                    <Box textAlign={'center'} p={1} fontWeight={'bold'}>Video de Presentación</Box>
                                                    <AspectRatio w={['100%', '100%']} ratio={2/2} >
                                                    <video controls={true} autoPlay={false} preload="auto" width="auto" height="50%"
                                                    >
                                                        <source src={p.url} controls />
                                                    </video> 
                                                    </AspectRatio>
                                                </Box>
                                            )
                                        })}
                                    </Flex> */}
                            </Box>
                            <Box zIndex={99999} >
                            </Box>
                        </Box>
                    </Flex>
                    <RenderIf isTrue={type=="presentacion"}>
                    <Box h={breakpoint1201 ? '14vh' : '14vh'}
                        position={'absolute'}
                        bgImage={`url(${Fondo})`}
                        w={'100%'}
                        zIndex={5}
                        left={0}                        
                        bottom={0}                           
                        >Down
                        </Box>
                    </RenderIf>

                </RenderIf>

                <RenderIf isTrue={!breakpoint && height >= 812 && height <= 906}>
                    <RenderIf isTrue={type=="presentacion"}>
                        <Box bgImage={`url(${Fondo})`}
                            position={'absolute'}
                            top={'-1'}
                            w={'100%'}
                            zIndex={5}
                            h={breakpoint1201 ? '13vh' : '13vh'}
                        >
                        </Box>
                    </RenderIf>
                    <Flex direction={'row'} w={'100%'} bgImage={`url(${Fondo})`} position={'relative'}>
                        <Box bgImage={`url(${Fondo})`}
                            zIndex={6}
                            w={'22%'}
                            position={'relative'}
                            left={"-1"}
                            h={'100vh'}>
                            <Center>
                                <Box mt={5} zIndex={9}>
                                    <Image src={LogoNctech} w={'150px'} h={'60px'}/>
                                </Box>
                            </Center>
                        </Box>
                        <Box w={'60%'}
                            position={'relative'}
                            left={'-2'}
                            top={0}
                            height="100%"
                            zIndex={'4'}
                            m={0}
                            p={0}
                        >
                            
                        <RenderIf isTrue={type=="presentacion"}>


                            <AspectRatio w={['90%', '100%']} ratio={19/11} position={'relative'} top={10}> 
                            <Box  borderRadius={15} boxShadow={'lg'} overflow={'hidden'} h={`${height}px`}  w={'100%'} m={0}>
                                <iframe
                                    style={{placeItems: 'center', display: 'grid', border: '0px', overflow:'hidden' }}
                                    id={'presentaciones'}
                                    src={src}
                                    width="101%"
                                    height="101%"
                                    allow="*"
                                    ref={listElement}
                                >
                                </iframe>
                                </Box>
                            </AspectRatio> 
                        
                        <div style={{
                                position: 'absolute',
                                bottom: '10%',
                                right: '0px',
                                width: '100px',
                                height: '20%',
                                backgroundColor: 'transparent',
                                zIndex: 2
                            }}>
                                <p style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: '#ff2',
                                    fontSize: '16px',
                                    textAlign: 'center'
                                }}></p>
                            </div>
                            <div style={{ 
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '74%',
                            backgroundColor: 'transparent',
                            zIndex: 1
                            }}>
                            <p style={{ 
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: '#fff',
                                fontSize: '20px',
                                textAlign: 'center'
                            }}></p>
                        </div> 
                        </RenderIf>
                        <RenderIf isTrue={type=="propuesta"}>
                           
                                    <Center>

                                        <Box id={'pdfs'} mt={20} >
                                            <Center>
                                                <Box color={'white'} bg={'gray.800'} alignSelf={'center'}
                                                    p={2}
                                                    borderRadius={5}
                                                    top={10}
                                                    zIndex={999}

                                                    fontSize={'13px'}
                                                > Total de Páginas {numPages}</Box>
                                                 <Flex>
                                                         {/* Botón de Descargar */}
                                                <RenderIf isTrue={descarga == true}>
                                                <Button
                                                    onClick={handleDownload}
                                                    bg="gray.800"
                                                    color="white"
                                                    _hover={{ bg: 'gray.700' }}
                                                    rounded="5"
                                                    h="40px"
                                                    ml={1}
                                                    zIndex={999}
                                                    fontSize={'13px'}
                                                >
                                                    Descargar 
                                                </Button>
                                             </RenderIf>

                                            {/* Botón de Imprimir */}
                                            <RenderIf isTrue={impresion == true}>
                                            <Button
                                                onClick={handlePrint}
                                                bg="gray.800"
                                                color="white"
                                                _hover={{ bg: 'gray.700' }}
                                                rounded="5"
                                                h="40px"
                                                zIndex={999}
                                                ml={1}
                                                fontSize={'13px'}
                                            >
                                                Imprimir
                                            </Button>
                                            </RenderIf>
                                            </Flex>
                                            </Center>
                                            {/* <Document file={src} onLoadSuccess={onDocumentLoadSuccess}>
                                                <Page height="750" pageNumber={pageNumber} />
                                            </Document> */}
                                                <Box
                                                position="fixed"
                                                top="50%"
                                                left="50%"
                                                transform="translate(-50%, -50%)"
                                                overflowY="scroll"
                                                maxHeight="90vh"
                                                rounded={15}
                                                mt={10}
                                                scroll={'none'}
                                                // Ajusta la altura máxima según tus necesidades
                                                >
                                          
                                                <Document file={src} onLoadSuccess={onDocumentLoadSuccess} >
                                                {Array.from(new Array(numPages), (el, index) => (
                                                <Page
                                                    key={index}
                                                    pageNumber={index + 1}
                                                    width={600}
                                                    height={'750'}
                                                    renderMode="pdf"
                                                    clip={{ x: 100, y: 100, width: 400, height: 400 }}
                                                    style={{ marginBottom: '5px' }}
                                                />
                                                ))}
                                                </Document>
                                              
                                                </Box>
                                            <Flex justify={'center'} position={'relative'} top={-5}>
                                                <Center>

                                                    {/* {pageNumber > 1 &&
                                                        <Button onClick={changePageBack}
                                                            bg={'gray.800'} color={'white'}
                                                            _hover={{ bg: 'gray.700' }}
                                                            rounded={'full'}
                                                            h={'40px'}

                                                            mr={1}
                                                        >
                                                            <svg width={'10px'} height={'20px'} viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M768 903.232l-50.432 56.768L256 512l461.568-448 50.432 56.768L364.928 512z" fill="#fff"></path></g></svg>
                                                        </Button>
                                                    }
                                                    {
                                                        pageNumber < numPages &&
                                                        <Button onClick={changePageNext}
                                                            bg={'gray.800'} color={'white'}
                                                            _hover={{ bg: 'gray.700' }}
                                                            rounded={'full'}
                                                            h={'40px'}

                                                        >
                                                            <svg width={'10px'} height={'20px'} viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z" fill="#fff"></path></g></svg>

                                                        </Button>
                                                    } */}
                                                          {/* Botón de Descargar */}
                                             {/* <RenderIf isTrue={descarga == true}>
                                                <Button
                                                    onClick={handleDownload}
                                                    bg="gray.800"
                                                    color="white"
                                                    _hover={{ bg: 'gray.700' }}
                                                    rounded="full"
                                                    h="40px"
                                                    ml={1}
                                                >
                                                    Descargar
                                                </Button>
                                             </RenderIf> */}

                                            {/* Botón de Imprimir */}
                                            {/* <RenderIf isTrue={impresion == true}>
                                            <Button
                                                onClick={handlePrint}
                                                bg="gray.800"
                                                color="white"
                                                _hover={{ bg: 'gray.700' }}
                                                rounded="full"
                                                h="40px"
                                                ml={1}
                                            >
                                                Imprimir
                                            </Button>
                                            </RenderIf> */}
                                            
                                            </Center>
                                           
                                            </Flex>


                                        </Box>
                                    </Center>
                        </RenderIf>
                        <RenderIf isTrue={type=="video"}>
                                <Center>
                                    <Box id={'videos'} mt={40}>
                                       
                                        <Flex justify={'center'} position={'relative'} top={-5}>
                                            <Center>

                                                <video controls autoPlay={false} preload="auto" width="auto" height="40%">
                                                    <source src={src} controls />
                                                </video>
                                            </Center>

                                      
                                        </Flex>


                                    </Box>
                                </Center>
                           </RenderIf>
                        </Box>
                        <Box zIndex={6} w={'15%'} h={'100vh'} transform={'90'} bgImage={`url(${Fondo})`}
                        border={'0'} left={'-3'} position={'relative'}>
                            <Box mt={5} ml={5} >
                                {/* <Flex justify={'center'}>
                                    {
                                        Imagen === undefined ?
                                        <Avatar src={"user.username"} alignSelf={'center'} _hover={{ cursor: 'pointer' }} w={'30px'} h={'30px'} />
                                        :
                                        <Avatar alignSelf={'center'} src={Imagen} _hover={{ cursor: 'pointer' }} w={'30px'} h={'30px'} />
                                    }
                                    <Flex direction={'column'}>
                                        <Box alignSelf={'center'} ml={'2'} color={'white'} fontWeight={'medium'} fontSize={'14px'}>Asesor de Ventas</Box>
                                        <Box alignSelf={'center'} ml={'2'} color={'white'} fontWeight={'medium'} fontSize={'14px'}>{proyectoRData.vendedorName}</Box>
                                        <p>Width: {width}</p>
                                        <p>Height: {height}</p>
                                    </Flex>
                                </Flex> */}
                                {/* <Flex justify={'start'} direction={'column'} zIndex={99999} w={'300px'} h={'500px'} mt={5}>
                                        {datos_videos.map((p, i) => {
                                            return (
                                                <Box rounded={'lg'} w={'auto'} bg={'gray.200'} overflow={'hidden'} >
                                                    <Box textAlign={'center'} p={1} fontWeight={'bold'}>Video de Presentación</Box>
                                                    <AspectRatio w={['100%', '100%']} ratio={2/2} >
                                                    <video controls={true} autoPlay={false} preload="auto" width="auto" height="50%"
                                                    >
                                                        <source src={p.url} controls />
                                                    </video> 
                                                    </AspectRatio>
                                                </Box>
                                            )
                                        })}
                                    </Flex> */}
                            </Box>
                            <Box zIndex={99999} >
                            </Box>
                        </Box>
                    </Flex>
                    <RenderIf isTrue={type=="presentacion"}>
                <Box h={breakpoint1201 ? '13vh' : '13vh'}
                    position={'absolute'}
                    bgImage={`url(${Fondo})`}
                    w={'100%'}
                    zIndex={5}
                    left={0}                        
                    bottom={0}                           
                    >Down
                    </Box>
                    </RenderIf>
                </RenderIf>

                <RenderIf isTrue={!breakpoint && height >= 775 && height <= 811} >
                    <RenderIf isTrue={type=="presentacion"}>
                        <Box bgImage={`url(${Fondo})`}
                            position={'absolute'}
                            top={'-1'}
                            w={'100%'}
                            zIndex={5}
                            h={breakpoint1201 ? '12vh' : '12vh'}
                        >
                        </Box>
                    </RenderIf>
                    <Flex direction={'row'} w={'100%'} bgImage={`url(${Fondo})`} position={'relative'}>
                        <Box bgImage={`url(${Fondo})`}
                            zIndex={6}
                            w={'22%'}
                            position={'relative'}
                            left={"-1"}
                            h={'100vh'}>
                            <Center>
                                <Box mt={5} zIndex={9}>
                                    <Image src={LogoNctech} w={'150px'} h={'60px'}/>
                                </Box>
                            </Center>
                        </Box>
                        <Box w={'60%'}
                            position={'relative'}
                            left={'-2'}
                            top={0}
                            height="100%"
                            zIndex={'4'}
                            m={0}
                            p={0}
                        >
                            
                        <RenderIf isTrue={type=="presentacion"}>


                            
                           
                            <AspectRatio w={['90%', '100%']} ratio={19/11} position={'relative'} top={10}> 
                                <Box borderRadius={15} boxShadow={'lg'} overflow={'hidden'} h={`${height}px`}  w={'100%'} m={0}>
                                    <iframe
                                        style={{placeItems: 'center', display: 'grid', border: '0px', overflow:'hidden' }}
                                        id={'presentaciones'}
                                        src={src}
                                        width="101%"
                                        height="101%"
                                        allow="*"
                                        ref={listElement}
                                    >
                                    </iframe>
                                    </Box>
                                </AspectRatio> 
                              
                            
                        
                        <div style={{
                                position: 'absolute',
                                bottom: '10%',
                                right: '0px',
                                width: '100px',
                                height: '20%',
                                backgroundColor: 'transparent',
                                zIndex: 2
                            }}>
                                <p style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: '#ff2',
                                    fontSize: '16px',
                                    textAlign: 'center'
                                }}></p>
                            </div>
                            <div style={{ 
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '76.5%',
                            backgroundColor: 'transparent',
                            zIndex: 1
                            }}>
                            <p style={{ 
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: '#fff',
                                fontSize: '20px',
                                textAlign: 'center'
                            }}></p>
                        </div> 
                        </RenderIf>
                        <RenderIf isTrue={type=="propuesta"}>
                                 <Center>

                                <Box id={'pdfs'} mt={16} >
                                    <Center>
                                        <Box color={'white'} bg={'gray.800'} alignSelf={'center'}
                                            p={2}
                                            borderRadius={5}
                                            top={0}
                                            zIndex={999}

                                            fontSize={'13px'}
                                        > Total de Páginas {numPages}</Box>
                                          <Flex>
                                                         {/* Botón de Descargar */}
                                                <RenderIf isTrue={descarga == true}>
                                                <Button
                                                    onClick={handleDownload}
                                                    bg="gray.800"
                                                    color="white"
                                                    _hover={{ bg: 'gray.700' }}
                                                    rounded="5"
                                                    h="40px"
                                                    ml={1}
                                                    zIndex={999}
                                                    fontSize={'13px'}
                                                >
                                                    Descargar 
                                                </Button>
                                             </RenderIf>

                                            {/* Botón de Imprimir */}
                                            <RenderIf isTrue={impresion == true}>
                                            <Button
                                                onClick={handlePrint}
                                                bg="gray.800"
                                                color="white"
                                                _hover={{ bg: 'gray.700' }}
                                                rounded="5"
                                                h="40px"
                                                zIndex={999}
                                                ml={1}
                                                fontSize={'13px'}
                                            >
                                                Imprimir
                                            </Button>
                                            </RenderIf>
                                            </Flex>
                                    </Center>
                                    {/* <Document file={src} onLoadSuccess={onDocumentLoadSuccess}>
                                        <Page height="700" pageNumber={pageNumber} />
                                    </Document> */}


                                                <Box
                                                position="fixed"
                                                top="50%"
                                                left="50%"
                                                transform="translate(-50%, -50%)"
                                                overflowY="scroll"
                                                maxHeight="89vh"
                                                rounded={15}
                                                mt={5}
                                                scroll={'none'}
                                                // Ajusta la altura máxima según tus necesidades
                                                >
                                          
                                                <Document file={src} onLoadSuccess={onDocumentLoadSuccess} >
                                                {Array.from(new Array(numPages), (el, index) => (
                                                <Page
                                                    key={index}
                                                    pageNumber={index + 1}
                                                    width={600}
                                                    height={'700'}
                                                    renderMode="pdf"
                                                    clip={{ x: 100, y: 100, width: 400, height: 400 }}
                                                    style={{ marginBottom: '5px' }}
                                                />
                                                ))}
                                                </Document>
                                              
                                                </Box>


                                    <Flex justify={'center'} position={'relative'} top={-5}>
                                        {/* <Center>

                                            {pageNumber > 1 &&
                                                <Button onClick={changePageBack}
                                                    bg={'gray.800'} color={'white'}
                                                    _hover={{ bg: 'gray.700' }}
                                                    rounded={'full'}
                                                    h={'40px'}

                                                    mr={1}
                                                >
                                                    <svg width={'10px'} height={'20px'} viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M768 903.232l-50.432 56.768L256 512l461.568-448 50.432 56.768L364.928 512z" fill="#fff"></path></g></svg>
                                                </Button>
                                            }
                                            {
                                                pageNumber < numPages &&
                                                <Button onClick={changePageNext}
                                                    bg={'gray.800'} color={'white'}
                                                    _hover={{ bg: 'gray.700' }}
                                                    rounded={'full'}
                                                    h={'40px'}

                                                >
                                                    <svg width={'10px'} height={'20px'} viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z" fill="#fff"></path></g></svg>

                                                </Button>
                                            }
                                        </Center> */}
                                         {/* Botón de Descargar */}
                                         {/* <RenderIf isTrue={descarga== true}>
                                                <Button
                                                    onClick={handleDownload}
                                                    bg="gray.800"
                                                    color="white"
                                                    _hover={{ bg: 'gray.700' }}
                                                    rounded="full"
                                                    h="40px"
                                                    ml={1}
                                                >
                                                    Descargar
                                                </Button>
                                             </RenderIf> */}

                                            {/* Botón de Imprimir */}
                                            {/* <RenderIf isTrue={impresion== true}>
                                            <Button
                                                onClick={handlePrint}
                                                bg="gray.800"
                                                color="white"
                                                _hover={{ bg: 'gray.700' }}
                                                rounded="full"
                                                h="40px"
                                                ml={1}
                                            >
                                                Imprimir
                                            </Button>
                                            </RenderIf> */}
                                    </Flex>


                                </Box>
                                </Center>
                        </RenderIf>
                        <RenderIf isTrue={type=="video"}>
                                <Center>
                                    <Box id={'videos'} mt={40}>
                                       
                                        <Flex justify={'center'} position={'relative'} top={-5}>
                                            <Center>

                                                <video controls autoPlay={false} preload="auto" width="auto" height="40%">
                                                    <source src={src} controls />
                                                </video>
                                            </Center>

                                      
                                        </Flex>


                                    </Box>
                                </Center>
                           </RenderIf>
                        </Box>
                        <Box zIndex={6} w={'15%'} h={'100vh'} transform={'90'} bgImage={`url(${Fondo})`}
                        border={'0'} left={'-3'} position={'relative'}>
                            <Box mt={5} ml={5} >
                                {/* <Flex justify={'center'}>
                                    {
                                        Imagen === undefined ?
                                        <Avatar src={"user.username"} alignSelf={'center'} _hover={{ cursor: 'pointer' }} w={'30px'} h={'30px'} />
                                        :
                                        <Avatar alignSelf={'center'} src={Imagen} _hover={{ cursor: 'pointer' }} w={'30px'} h={'30px'} />
                                    }
                                    <Flex direction={'column'}>
                                        <Box alignSelf={'center'} ml={'2'} color={'white'} fontWeight={'medium'} fontSize={'14px'}>Asesor de Ventas</Box>
                                        <Box alignSelf={'center'} ml={'2'} color={'white'} fontWeight={'medium'} fontSize={'14px'}>{proyectoRData.vendedorName}</Box>
                                        <p>Width: {width}</p>
                        <p>Height: {height}</p>
                                    </Flex>
                                </Flex> */}
                                {/* <Flex justify={'start'} direction={'column'} zIndex={99999} w={'300px'} h={'500px'} mt={5}>
                                        {datos_videos.map((p, i) => {
                                            return (
                                                <Box rounded={'lg'} w={'auto'} bg={'gray.200'} overflow={'hidden'} >
                                                    <Box textAlign={'center'} p={1} fontWeight={'bold'}>Video de Presentación</Box>
                                                    <AspectRatio w={['100%', '100%']} ratio={2/2} >
                                                    <video controls={true} autoPlay={false} preload="auto" width="auto" height="50%"
                                                    >
                                                        <source src={p.url} controls />
                                                    </video> 
                                                    </AspectRatio>
                                                </Box>
                                            )
                                        })}
                                    </Flex> */}
                            </Box>
                            <Box zIndex={99999} >
                            </Box>
                        </Box>
                    </Flex>
                    <RenderIf isTrue={type=="presentacion"}>
                    <Box h={breakpoint1201 ? '10vh' : '10vh'}
                        position={'absolute'}
                        bgImage={`url(${Fondo})`}
                        w={'100%'}
                        zIndex={5}
                        left={0}                        
                        bottom={0}                           
                        >Down
                        </Box>
                        </RenderIf>
                </RenderIf>

                <RenderIf isTrue={!breakpoint && height >= 500 && height <= 774} >
                <RenderIf isTrue={type=="presentacion"}>
                    <Box bgImage={`url(${Fondo})`}
                        position={'absolute'}
                        top={'-1'}
                        w={'100%'}
                        zIndex={5}
                        h={breakpoint1201 ? '12vh' : '12vh'}
                    >
                    </Box>
                    </RenderIf>
                    <Flex direction={'row'} w={'100%'} bgImage={`url(${Fondo})`} position={'relative'}>
                        <Box bgImage={`url(${Fondo})`}
                            zIndex={6}
                            w={'22%'}
                            position={'relative'}
                            left={"-1"}
                            h={'100vh'}>
                            <Center>
                                <Box mt={5} zIndex={9}>
                                    <Image src={LogoNctech} w={'150px'} h={'60px'}/>
                                </Box>
                            </Center>
                        </Box>
                        <Box w={'60%'}
                            position={'relative'}
                            left={'-2'}
                            top={0}
                            height="100%"
                            zIndex={'4'}
                            m={0}
                            p={0}
                        >
                            
                        <RenderIf isTrue={type=="presentacion"}>

                            <AspectRatio w={['90%', '100%']} ratio={19/11} position={'relative'} top={20} > 
                                <Box  boxShadow={'lg'} borderRadius={15} overflow={'hidden'} h={'100%'}  w={'101%'} m={0}>
                                    <iframe
                                        style={{placeItems: 'center', display: 'grid', border: '0px', overflow:'hidden' }}
                                        id={'presentaciones'}
                                        src={src}
                                        width="101%"
                                        height="102%"
                                        allow="*"
                                        ref={listElement}
                                    >
                                    </iframe>
                                    </Box>
                                </AspectRatio> 
                              
                            
                        
                        <div style={{
                                position: 'absolute',
                                bottom: '10%',
                                right: '0px',
                                width: '100px',
                                height: '20%',
                                backgroundColor: 'transparent',
                                zIndex: 2
                            }}>
                                <p style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: '#ff2',
                                    fontSize: '16px',
                                    textAlign: 'center'
                                }}></p>
                            </div>
                            <div style={{ 
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '72%',
                            backgroundColor: 'transparent',
                            zIndex: 1
                            }}>
                            <p style={{ 
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: '#fff',
                                fontSize: '20px',
                                textAlign: 'center'
                            }}></p>
                        </div> 
                        </RenderIf>
                        <RenderIf isTrue={type=="propuesta"}>
                        <Center>

                            <Box id={'pdfs'} mt={'60px'} >
                                <Center>
                                    <Box color={'white'} bg={'gray.800'} alignSelf={'center'}
                                        p={2}
                                        borderRadius={5}
                                        top={10}
                                        zIndex={999}

                                        fontSize={'13px'}
                                    > Total de Páginas {numPages}</Box>

                                    <Flex>
                                                         {/* Botón de Descargar */}
                                                <RenderIf isTrue={descarga == true}>
                                                <Button
                                                    onClick={handleDownload}
                                                    bg="gray.800"
                                                    color="white"
                                                    _hover={{ bg: 'gray.700' }}
                                                    rounded="5"
                                                    h="40px"
                                                    ml={1}
                                                    zIndex={999}
                                                    fontSize={'13px'}
                                                >
                                                    Descargar 
                                                </Button>
                                             </RenderIf>

                                            {/* Botón de Imprimir */}
                                            <RenderIf isTrue={impresion == true}>
                                            <Button
                                                onClick={handlePrint}
                                                bg="gray.800"
                                                color="white"
                                                _hover={{ bg: 'gray.700' }}
                                                rounded="5"
                                                h="40px"
                                                zIndex={999}
                                                ml={1}
                                                fontSize={'13px'}
                                            >
                                                Imprimir
                                            </Button>
                                            </RenderIf>
                                            </Flex>
                                </Center>
                                {/* <Document file={src} onLoadSuccess={onDocumentLoadSuccess}>
                                    <Page height="650" pageNumber={pageNumber} />
                                </Document> */}

                                    <Box
                                                position="fixed"
                                                top="50%"
                                                left="50%"
                                                transform="translate(-50%, -50%)"
                                                overflowY="scroll"
                                                maxHeight="89vh"
                                                rounded={15}
                                                mt={5}
                                                scroll={'none'}
                                                // Ajusta la altura máxima según tus necesidades
                                                >
                                          
                                                <Document file={src} onLoadSuccess={onDocumentLoadSuccess} >
                                                {Array.from(new Array(numPages), (el, index) => (
                                                <Page
                                                    key={index}
                                                    pageNumber={index + 1}
                                                    width={600}
                                                    height={'700'}
                                                    renderMode="pdf"
                                                    clip={{ x: 100, y: 100, width: 400, height: 400 }}
                                                    style={{ marginBottom: '5px' }}
                                                />
                                                ))}
                                                </Document>
                                              
                                                </Box>
                                
                                <Flex justify={'center'} position={'relative'} top={-5}>
                                    {/* <Center>

                                        {pageNumber > 1 &&
                                            <Button onClick={changePageBack}
                                                bg={'gray.800'} color={'white'}
                                                _hover={{ bg: 'gray.700' }}
                                                rounded={'full'}
                                                h={'40px'}

                                                mr={1}
                                            >
                                                <svg width={'10px'} height={'20px'} viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M768 903.232l-50.432 56.768L256 512l461.568-448 50.432 56.768L364.928 512z" fill="#fff"></path></g></svg>
                                            </Button>
                                        }
                                        {
                                            pageNumber < numPages &&
                                            <Button onClick={changePageNext}
                                                bg={'gray.800'} color={'white'}
                                                _hover={{ bg: 'gray.700' }}
                                                rounded={'full'}
                                                h={'40px'}

                                            >
                                                <svg width={'10px'} height={'20px'} viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z" fill="#fff"></path></g></svg>

                                            </Button>
                                        }
                                    </Center> */}
                                     {/* Botón de Descargar */}
                                     {/* <RenderIf isTrue={descarga== true}>
                                                <Button
                                                    onClick={handleDownload}
                                                    bg="gray.800"
                                                    color="white"
                                                    _hover={{ bg: 'gray.700' }}
                                                    rounded="full"
                                                    h="40px"
                                                    ml={1}
                                                >
                                                    Descargar
                                                </Button>
                                             </RenderIf> */}

                                            {/* Botón de Imprimir */}
                                            {/* <RenderIf isTrue={impresion== true}>
                                            <Button
                                                onClick={handlePrint}
                                                bg="gray.800"
                                                color="white"
                                                _hover={{ bg: 'gray.700' }}
                                                rounded="full"
                                                h="40px"
                                                ml={1}
                                            >
                                                Imprimir
                                            </Button>
                                            </RenderIf> */}
                                </Flex>


                            </Box>
                            </Center>
                        </RenderIf>
                        <RenderIf isTrue={type=="video"}>
                                <Center>
                                    <Box id={'videos'} mt={40}>
                                       
                                        <Flex justify={'center'} position={'relative'} top={-5}>
                                            <Center>

                                                <video controls autoPlay={false} preload="auto" width="auto" height="40%">
                                                    <source src={src} controls />
                                                </video>
                                            </Center>

                                      
                                        </Flex>


                                    </Box>
                                </Center>
                           </RenderIf>
                        </Box>
                        <Box zIndex={6} w={'15%'} h={'100vh'} transform={'90'} bgImage={`url(${Fondo})`}
                        border={'0'} left={'-3'} position={'relative'}>
                            <Box mt={5} ml={5} >
                                {/* <Flex justify={'center'}>
                                    {
                                        Imagen === undefined ?
                                        <Avatar src={"user.username"} alignSelf={'center'} _hover={{ cursor: 'pointer' }} w={'30px'} h={'30px'} />
                                        :
                                        <Avatar alignSelf={'center'} src={Imagen} _hover={{ cursor: 'pointer' }} w={'30px'} h={'30px'} />
                                    }
                                    <Flex direction={'column'}>
                                        <Box alignSelf={'center'} ml={'2'} color={'white'} fontWeight={'medium'} fontSize={'14px'}>Asesor de Ventas</Box>
                                        <Box alignSelf={'center'} ml={'2'} color={'white'} fontWeight={'medium'} fontSize={'14px'}>{proyectoRData.vendedorName}</Box>
                                        <p>Width: {width}</p>
                                        <p>Height: {height}</p>
                                    </Flex>
                                </Flex> */}
                                {/* <Flex justify={'start'} direction={'column'} zIndex={99999} w={'300px'} h={'500px'} mt={5}>
                                        {datos_videos.map((p, i) => {
                                            return (
                                                <Box rounded={'lg'} w={'auto'} bg={'gray.200'} overflow={'hidden'} >
                                                    <Box textAlign={'center'} p={1} fontWeight={'bold'}>Video de Presentación</Box>
                                                    <AspectRatio w={['100%', '100%']} ratio={2/2} >
                                                    <video controls={true} autoPlay={false} preload="auto" width="auto" height="50%"
                                                    >
                                                        <source src={p.url} controls />
                                                    </video> 
                                                    </AspectRatio>
                                                </Box>
                                            )
                                        })}
                                    </Flex> */}
                            </Box>
                            <Box zIndex={99999} >
                            </Box>
                        </Box>
                    </Flex>
                    <RenderIf isTrue={type=="presentacion"}>
                    <Box h={breakpoint1201 ? '7vh' : '7vh'}
                        position={'absolute'}
                        bgImage={`url(${Fondo})`}
                        w={'100%'}
                        zIndex={5}
                        left={0}                        
                        bottom={0}                           
                        >Down
                        </Box>
                        </RenderIf>
                </RenderIf>
                {/* Vista Movil */}
                <RenderIf isTrue={breakpoint} >
                        <RenderIf isTrue={type=="propuesta"}>
                        <Box 
                            bgImage={`url(${Fondo})`}
                            position={'absolute'}
                            top={'0'}
                            w={'99.89%'}
                            zIndex={9}
                            h={'15vh'}
                        >
                                
                            <Flex direction={'row'} justify={'space-between'}>
                                <Box mt={5} pl={5} zIndex={9}>
                                            <Image src={LogoNctech} w={'150px'} h={'60px'}/>
                                </Box>
                            
                            </Flex>
                            
                        
                            
                        </Box>
                        </RenderIf>
                        <RenderIf isTrue={type=="presentacion"}>
                            <Box 
                                bgImage={`url(${Fondo})`}
                                position={'absolute'}
                                top={'0'}
                                w={'99.89%'}
                                zIndex={9}
                                h={'36vh'}
                            >
                                    
                                <Flex direction={'row'} justify={'space-between'}>
                                    <Box mt={5} pl={5} zIndex={9}>
                                                <Image src={LogoNctech} w={'150px'} h={'60px'}/>
                                    </Box>
                                
                                </Flex>
                                
                                <Center>
                                    <Image src={EMBLEMA} p={5} mt={10}/>
                                </Center>
                                
                            </Box>
                        </RenderIf>
                        <RenderIf isTrue={type=="video"}>
                            <Box 
                                bgImage={`url(${Fondo})`}
                                position={'absolute'}
                                top={'0'}
                                w={'99.89%'}
                                zIndex={9}
                                h={'36vh'}
                            >
                                    
                                <Flex direction={'row'} justify={'space-between'}>
                                    <Box mt={5} pl={5} zIndex={9}>
                                                <Image src={LogoNctech} w={'150px'} h={'60px'}/>
                                    </Box>
                                
                                </Flex>
                                
                                <Center>
                                    <Image src={EMBLEMA} p={5} mt={10}/>
                                </Center>
                                
                            </Box>
                        </RenderIf>

                    <Box w={'100%'}
                            height="100%"  
                            zIndex={'8'}
                            >
                        <RenderIf isTrue={type == "presentacion"}>

                           
                                <Box boxShadow={'lg'} borderRadius={15} overflow={'hidden'} h={'100%'} w={'101%'} m={0}>
                                    <iframe
                                        style={{ placeItems: 'center', display: 'grid', border: '0px', overflow: 'hidden' }}
                                        id={'presentaciones'}
                                        src={src}
                                        width="101%"
                                        height="101%"
                                        allow="*"
                                        ref={listElement}
                                    >
                                    </iframe>
                                </Box>
                          
                        </RenderIf>
                        <RenderIf isTrue={type == "propuesta"}>
                            <Center mt={20}>

                                <Box id={'pdfs'} mt={40} >
                                    
                                    <Box
                                        position="fixed"
                                        top="50%"
                                        left="50%"
                                        transform="translate(-50%, -50%)"
                                        overflowY="scroll"
                                        maxHeight="56vh"
                                        rounded={15}
                                        mt={10}
                                        
                                         // Ajusta la altura máxima según tus necesidades
                                        >
                                    <Center>
                                        {/* <Box color={'white'} bg={'gray.800'} alignSelf={'center'}
                                            p={2}
                                            borderRadius={5}
                                            position={'absolute'}
                                            top={36}
                                            zIndex={999}

                                            fontSize={'13px'}
                                        > Página {pageNumber} de {numPages}</Box> */}
                                        <Box color={'white'} bg={'gray.800'} alignSelf={'center'}
                                            position={'absolute'}
                                            p={2}
                                            borderRadius={5}
                                            top={0}
                                            zIndex={99}

                                            fontSize={'13px'}
                                        > Total de Páginas {numPages}</Box>
         
                                    </Center>
                                    <Document file={src} onLoadSuccess={onDocumentLoadSuccess} height="400" width={'350'}>
                                     {Array.from(new Array(numPages), (el, index) => (
                                        <Page
                                            key={index}
                                            pageNumber={index + 1}
                                            width={400}
                                            height={350}
                                            renderMode="pdf"
                                            clip={{ x: 100, y: 100, width: 400, height: 400 }}
                                            style={{ marginBottom: '5px' }}
                                        />
                                        ))}
                                     {/* <Page height="400px" width={'350'} pageNumber={pageNumber} clip={{ x: 100, y: 100, width: 400, height: 400 }}/> /// ORIGINAL
                                        <p>Total de páginas: {numPages}</p>
                                        {pageInfo.map((page, index) => (
                                            <div key={index}>
                                            <p>Página {index + 1}</p>
                                            
                                            <p>Ancho: {page.width}</p>
                                            <p>Altura: {page.height}</p>
                                            </div>
                                        ))} */}
                                    </Document>
                                    </Box>
                                    <Flex justify={'center'} position={'relative'} top={-5}>
                                        {/* <Center>

                                            {pageNumber > 1 &&
                                                <Button onClick={changePageBack}
                                                    bg={'gray.800'} color={'white'}
                                                    _hover={{ bg: 'gray.700' }}
                                                    rounded={'full'}
                                                    h={'40px'}

                                                    mr={1}
                                                >
                                                    <svg width={'10px'} height={'20px'} viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M768 903.232l-50.432 56.768L256 512l461.568-448 50.432 56.768L364.928 512z" fill="#fff"></path></g></svg>
                                                </Button>
                                            }
                                            {
                                                pageNumber < numPages &&
                                                <Button onClick={changePageNext}
                                                    bg={'gray.800'} color={'white'}
                                                    _hover={{ bg: 'gray.700' }}
                                                    rounded={'full'}
                                                    h={'40px'}

                                                >
                                                    <svg width={'10px'} height={'20px'} viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z" fill="#fff"></path></g></svg>

                                                </Button>
                                            }
                                        </Center> */}
                                         {/* Botón de Descargar */}
                                         {/* <RenderIf isTrue={descarga== true}>
                                                <Button
                                                    onClick={handleDownload}
                                                    bg="gray.800"
                                                    color="white"
                                                    _hover={{ bg: 'gray.700' }}
                                                    rounded="full"
                                                    h="40px"
                                                    ml={1}
                                                >
                                                    Descargar
                                                </Button>
                                             </RenderIf> */}
                                    </Flex>


                                </Box>
                            </Center>
                        </RenderIf>
                        <RenderIf isTrue={type=="video"}>
                                <Center>
                                    <Box id={'videos'} mt={80}>
                                       
                                        <Flex justify={'center'} position={'relative'} top={5}>
                                            <Center>

                                                <video id="myVideo" controls autoPlay={false} preload="auto" width="auto" height="50%">
                                                    <source src={src} controls />
                                                </video>
                                            </Center>

                                      
                                        </Flex>


                                    </Box>
                                </Center>
                           </RenderIf>
                    </Box>
                    <RenderIf isTrue={type=="presentacion"}>
                    <Box  h={'36.7vh'} 
                            position={'absolute'} 
                            bgImage={`url(${Fondo})`}
                            bottom={0} 
                            w={'99.89%'} 
                            zIndex={9}>
                                
                        </Box>
                    </RenderIf>
                    <Box zIndex={9999} bg={'red.50'}>
                       
                    </Box>
                </RenderIf>
            </Flex>
        </Box>
    );
};
//REDUX
const mapStateProps =(state) => ({
    proyectoRData: state.proyectoReducer.proyectoRData,
    Imagen: state.avatarReducer.Image,
    isLoading: state.avatarReducer.isLoading,
    error: state.avatarReducer.error,
    })
const ShareProject = connect(mapStateProps,{get_avatar, get_proyecto})(_ShareProject)

export default ShareProject;

