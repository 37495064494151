import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import useToken from '../useToken';

function LinkedinAuthCallback() {
  const [auth, setAuth] = useState()
  const location = useLocation()
  const { token, setToken } = useToken();
  useEffect(() => {
    if (!location) {
      return
    }
    const { search } = location
    axios({
      method: 'GET',
      url: `https://s-api-users-production-0e0b865da12a.herokuapp.com/auth/linkedin/callback${search}`,
    })
      .then((res) => res.data)
      .then(setToken)
      .then(window.location = "/")
      
     // .then({setAuth: res.data, setToken: res.data.jwt })
  }, [location])
  console.log(location)
  return (
    <div>
      {auth && (
        <>
          <div>Jwt: {token.jwt}</div>
          <div>User Id: {token.user.id}</div>
          <div>Provider: {token.user.provider}</div>
        </>
      )}
    </div>
  )
}

export default LinkedinAuthCallback